import React from "react";
import { ReactComponent as GooglePlay } from "../assets/badges/google-play.svg";
import { ReactComponent as AppStore } from "../assets/badges/app-store.svg";

const DownloadAppCTA = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center py-8 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="tracking-tight ">
          <div className="text-sm text-gray-500">Don't have an account?</div>
          <div className="font-extrabold text-lg text-gray-900">
            Download Storius App and create an account now!
          </div>
        </div>
        <div className="mt-8 flex justify-center">
          <div className="flex items-center justify-center gap-x-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.storius.storiusapp"
              className="hover:scale-105 transition-all ease-in-out"
            >
              <GooglePlay className="h-10" />
            </a>
            <a
              href="https://apps.apple.com/hk/app/storius-app/id1581928786"
              className="hover:scale-105 transition-all ease-in-out"
            >
              <AppStore className="h-10" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppCTA;
