import { useState } from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";

import ProcessingBtn from "./ProcessingBtn";
import DownloadAppCTA from "./DownloadAppCTA";
import env from "../configs/config";

const RedeemForm = ({ setPage, setResult }) => {
  const [isLoading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const redeemUser = useMutation((form) => {
    return axios.post(`${env.api_endpoint}/coupons/redeem`, form);
  });

  const formik = useFormik({
    initialValues: {
      username: searchParams.get("username") || "",
      code: searchParams.get("code") || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().trim().required("This field is required"),
      code: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      setLoading(true);
      try {
        let payload = await redeemUser.mutateAsync(formik.values);
        console.log(payload);
        setResult(payload.data);
        resetForm();
        setPage(1);
      } catch (err) {
        if (err.response?.data?.errors?.msg[0].msg === "USER_NOT_FOUND") {
          setErrors({ username: "User not found" });
        } else if (
          err.response?.data?.errors?.msg[0].msg === "COUPON_NOT_FOUND"
        ) {
          setErrors({ code: "Coupon not found" });
        } else if (err.response?.data?.errors?.msg === "USER_HAS_REDEEMED") {
          setErrors({ code: "User has already redeemed this code" });
        } else if (err.response?.data?.errors?.msg === "COUPON_OUT_OF_STOCK") {
          setErrors({ code: "This code has exhausted" });
        } else {
          setErrors({ code: err });
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="rounded-md -space-y-px">
      <form onSubmit={formik.handleSubmit} className="grid gird-col-1 gap-y-4">
        <div>
          <label className="block my-2 text-sm font-medium text-gray-900">
            Your Username:
          </label>
          <input
            type="text"
            name="username"
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-teal-600 focus:border-teal-600 focus:z-10 sm:text-sm"
            placeholder="Your username/email..."
            required
            onChange={formik.handleChange}
            value={formik.values.username}
          />
          <p className="text-red-600 text-sm">
            {formik.errors.username && formik.touched.username
              ? `Error ${formik.errors.username}`
              : null}
          </p>
        </div>
        <div>
          <label className="block my-2 text-sm font-medium text-gray-900">
            Your Code:
          </label>
          <input
            autoFocus
            type="text"
            name="code"
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-teal-600 focus:border-teal-600 focus:z-10 sm:text-sm"
            placeholder="Your redemption code..."
            onChange={formik.handleChange}
            value={formik.values.code}
          />
          <p className="text-red-600 text-sm">
            {formik.errors.code && formik.touched.code
              ? `Error: ${formik.errors.code}`
              : null}
          </p>
        </div>
        <div>
          {isLoading && <ProcessingBtn />}
          {!isLoading && (
            <button
              type="submit"
              className="mt-6 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#00c5c5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
            >
              Redeem
            </button>
          )}
        </div>
      </form>

      {/* Download App CTA */}
      <DownloadAppCTA />
    </div>
  );
};

export default RedeemForm;
