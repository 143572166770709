import { ReactComponent as AppLogo } from "../assets/app_icon.svg";
import { useState } from "react";

// Download Link
// http://onelink.to/zte8zd

const Message = () => {
  let [isOpen, setIsOpen] = useState(true);

  if (isOpen) {
    return (
      <>
        <div className="h-20 text-white px-4 py-2 w-screen sm:w-96 border-0 rounded-t-[20px] relative bg-storius-darkgray flex items-center">
          <span className="inline-block mr-auto align-middle">
            <AppLogo className="h-12" />
          </span>
          <span className="inline-block align-middle mr-auto">
            <p className="font-bold text-[12px]">Faster & Better Experience</p>
            <p className="font-normal text-[10px]">
              Listen to local stories at east
            </p>
          </span>
          <a
            href="http://onelink.to/zte8zd"
            className="h-6 rounded-full flex justify-center items-center px-2 py-1.5 bg-storius-orange text-white font-bold text-[10px]"
          >
            <span>Get the app</span>
          </a>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="bg-transparent text-2xl font-semibold leading-none outline-none focus:outline-none mx-auto"
          >
            <span>×</span>
          </button>
        </div>
      </>
    );
  }
};

export default Message;
