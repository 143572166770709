import React from "react";
import moment from "moment";
import { ReactComponent as GooglePlay } from "../assets/badges/google-play.svg";
import { ReactComponent as AppStore } from "../assets/badges/app-store.svg";

const Success = ({ setPage, result }) => {
  return (
    <div class="flex flex-col gap-y-4">
      <div className="grid grid-cols-1 gap-y-4">
        <div className="text-emerald-600 rounded-lg text-center text-xl font-bold">
          Redeem successfully!
        </div>
        <div className="bg-rose-500 text-white py-2 rounded-lg text-center font-bold">
          Exp: {moment(result.premiumExpires).format("MMMM Do YYYY, h:mm a")} (
          {moment(result.premiumExpires).from()})
        </div>
      </div>

      {/* App Download */}
      <div className="flex items-center justify-center gap-x-4">
        <a
          href="https://play.google.com/store/apps/details?id=com.storius.storiusapp"
          className="hover:scale-105 transition-all ease-in-out"
        >
          <GooglePlay className="h-10" />
        </a>
        <a
          href="https://apps.apple.com/hk/app/storius-app/id1581928786"
          className="hover:scale-105 transition-all ease-in-out"
        >
          <AppStore className="h-10" />
        </a>
      </div>

      <div className="text-center">
        <button
          className="mt-6 text-sm text-blue-700"
          onClick={() => {
            setPage(0);
          }}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default Success;
