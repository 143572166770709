import { useState } from "react";

import Header from "../components/Header.js";
import RedeemForm from "../components/RedeemForm.js";
import Success from "../components/Success.js";

export default function Home() {
  const [page, setPage] = useState(0);

  // Form
  const [result, setResult] = useState({
    message: "Your account has been successfully redeemed!",
    expiredAt: "",
  });
  return (
    <>
      <Header />
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="my-6 text-center text-2xl font-bold text-gray-800">
              Storius Redeem
            </h2>
            {page === 0 && (
              <RedeemForm setPage={setPage} setResult={setResult} />
            )}
            {page === 1 && <Success setPage={setPage} result={result} />}
          </div>
        </div>
      </div>
    </>
  );
}
