import { ReactComponent as StoriusLogo } from "../assets/logo.svg";

const Header = () => {
  return (
    <header>
      <nav
        className="
          z-10
          flex flex-nowrap
          items-start
          justify-between
          w-full
          py-4
          px-4
          text-lg text-gray-700
          bg-white
          drop-shadow
          top-0 
          h-18
        "
      >
        <a className="my-2" href="/">
          <StoriusLogo className="h-6" />
        </a>
      </nav>
    </header>
  );
};

export default Header;
